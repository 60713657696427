.user-analytics-data {
  margin-top: 40px;
  padding-left: 2rem;
}

.user-analytics-data h5 {
  margin-bottom: 0.8rem;
}

.analytics-bottom {
  margin-bottom: 10px;
}

.skeleton-space {
  margin-left: 10rem;
}
.min-height-adjust {
  min-height: 300px;
}

.title-margin-adjust {
  margin-left: -9px !important;
}

.action-padding-adjust {
  padding-left: 15px !important;
}

.text-bold-trainingName {
  font-weight: 600;
}

.user-left-margin {
  margin-left: 50px;
}

.mark-as-agent-button {
  padding: 7px;
  /* margin-right: 8px; */
  font-size: 9px;
}

.btn-user-section {
  background-color: #001167;
  border-color: #001167;
  color: #fff;
}

.btn-user-section:hover {
  background-color: #fff;
  border-color: #001167;
  color: #001167;
}

.btn-marked-agent {
  display: inline-block;
  font-weight: 500 !important;
  color: rgb(0, 75, 145);
  margin-right: 8px !important;
  font-size: 10px;
  user-select: none;
}

@media (max-width: 992px) {
  .external-page-image {
    padding-top: 20rem !important;
  }
}

.company-name-font {
  font-size: 3rem !important;
}
